import "./footer.style.scss";
import classNames from "classnames";
import { useLoaderData } from "react-router-dom";
export const Footer = ()=>{
    const { navigation } = useLoaderData();
    return (
        <footer className={"footer"}>
            <nav className={"footer-nav"}>
                <ul className={"footer-nav-list"}>
                    {navigation?.footer?.map(({ link, anchor, target, name, fontColor, bgColor }, i)=>{
                        const itemClass = classNames({
                            'footer-nav-item': true,
                            [`${bgColor ?? "secondary"}-bg`]: true,
                            [`${fontColor ?? "white"}-color`]: true
                        })
                        return (
                            <li key={i} className={itemClass}><a href={link ?? anchor ?? ""} target={target}>{name}</a></li>
                        )
                    })}
                </ul>
            </nav>
        </footer>
    )
}
