import { NotFoundSection } from "../components/not-found-section/not-found-section.view";
import MiniLogo from "../assets/mini-logo.svg";

const NotFound = ()=>{
    return (
        <main className={"main"}>
            <header className="header header-not-found">
                <div className="header-row">
                    <div className="header-logo_mini">
                        <img src={MiniLogo} alt="muur.info"/>
                    </div>
                </div>
            </header>
            <NotFoundSection/>
        </main>
    )
}
export default NotFound;
