import { useState } from "react";
import "./section.style.scss";
import { useLoaderData } from "react-router-dom";
import classNames from "classnames";
import { Background, Parallax } from "react-parallax";
import ButtonPopup from "../button-popup/button-popup.view";
import { Popup } from "../popup/popup.view";
import { MuurSlider } from "../slider/slider.view";
import { Infographic } from "../infographic/infographic.view";
export const Section = ({ targetId })=>{
    const { sections } = useLoaderData();
    const [isOpenedPopup, setIsOpenedPopup] = useState(false);
    const openPopupHandler = () => {
        setIsOpenedPopup(true);
    }
    const closePopupHandler = () => {
        setIsOpenedPopup(false);
    }
    if (sections[targetId]) {
        const { id, image, headline, subHeadline, description, popupButton, background, fontColor, fillColor, headlineAlignment, subHeadline2line, headline2line, slider, infographic } = sections[targetId];
        const sectionClass = classNames({
            [`${background?.section ?? "primary"}-bg`]: true,
            [`${fontColor?.section ?? "white"}-color`]: true
        });
        const headlineClass = classNames({
            'section-slider_headline': true,
            [`${background?.headline ?? "secondary"}-bg`]: true,
            [`${fontColor?.headline ?? "white"}-color`]: true
        });
        const subHeadlineClass = classNames({
            'section-slider_headline_sub': true,
            [`${background?.subHeadline ?? "secondary"}-bg`]: true,
            [`${fontColor?.subHeadline ?? "white"}-color`]: true
        });
        const buttonPopupClass = classNames({
            'section-slider_button': true,
            [`${fillColor?.popupButton ?? "third"}-fill`]: true,
            [`${fontColor?.popupButton ?? "white"}-color`]: true
        });
        const infographicClass = classNames({
            'section-slider_infographic': true,
            [`${background?.section ?? "primary"}-bg`]: true,
            [`${fontColor?.section ?? "white"}-color`]: true
        });
        const headlineTop = headlineAlignment==="top";
        const Headline = ()=>(
            <h2 className="section-slider_h2__wrapper muur-h2__wrapper">
                {headline && <div className={`muur-h2 ${headlineClass}`} data-aos="fade-up">{headline}</div>}
                {headline2line && <div className={`muur-h2 ${headlineClass}`} data-aos="fade-up">{headline2line}</div>}
                {subHeadline && <div className={`muur-h2_sub ${subHeadlineClass}`} data-aos="fade-up">{subHeadline}</div>}
                {subHeadline2line && <div className={`muur-h2_sub ${subHeadlineClass}`} data-aos="fade-up">{subHeadline2line}</div>}
            </h2>
        );
        return (
            <>
            <section id={id} className={`section section-slider ${sectionClass}`}>
                <div className="section-slider_container">
                    <Parallax strength={100} blur={{ min: -3, max: 3 }}>
                        <Background className="section-slider_bg">
                            <img src={image} alt="fill murray" />
                        </Background>
                        <div className={"section-slider_preview"}>
                            {!headlineTop && <Headline/>}
                        </div>
                        <div className={`section-slider_content ${sectionClass}`}>
                            {headlineTop && <Headline/>}
                            <div className="section-slider_row">
                                <div className="section-slider_description muur-description" data-aos="fade-up" dangerouslySetInnerHTML={{ __html: description }}/>
                                {slider && <ButtonPopup data={popupButton} classes={buttonPopupClass} onClick={openPopupHandler}/>}
                            </div>
                        </div>
                    </Parallax>
                    {infographic && <Infographic {...infographic} classes={infographicClass}/>}
                </div>
            </section>
                {slider && isOpenedPopup && <Popup closeAction={closePopupHandler}><MuurSlider data={slider}/></Popup>}
            </>
        )
    }
    return null;
}
