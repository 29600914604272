import "./slider.style.scss";
import Slider from "react-slick";
import { MuurSlide } from "./slide/slide.view";
export const MuurSlider = ({ data })=>{
    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true
    };

    if (data) {
        return (
            <div className={`section section-popup_slider`} data-aos="fade">
                <div className="section-popup_slider_container">
                    <Slider {...sliderSettings}>
                        {data?.map((slide, i)=><MuurSlide key={i} {...slide}/>)}
                    </Slider>
                </div>
            </div>
        )
    }
    return null;
}
