import "./video.style.scss";
import { useLoaderData } from "react-router-dom";
import { VideoPlayer } from "../video-player/video-player.view";

export const Video = ({id})=>{
    const { video } = useLoaderData();
    if (!video?.[id]) return;
    return (
        <section  className={`section section-video`}>
            <div className="section-video_container">
                <VideoPlayer video={video[id]}/>
            </div>
        </section>
    )
}
