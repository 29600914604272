import { createRef, useEffect, useState } from "react";
import Lottie from "lottie-react";
import "./header.style.scss";
import { Navigation } from "../navigation/navigation.view";
import Logo from "../../assets/logo.svg";
import MiniLogo from "../../assets/mini-logo.svg";
import { ReactComponent as IconCloseNav } from "../../assets/icons/close-nav.svg";
import { ReactComponent as ButtonNav } from "../../assets/icons/nav.svg";
import classNames from "classnames";
import { useLoaderData } from "react-router-dom";
import LogoAnimation from "../../assets/lottie/logo.json";

export const Header = ()=>{
    const { navigation } = useLoaderData();
    const [isOpenNav, setIsOpenNav] = useState(false);

    const refHeader = createRef(null);
    let timer;
    const isSticky = async () => {
        const scrollTop = window.scrollY;
        if(timer && scrollTop <= refHeader.current.offsetHeight/5) {
            window.clearTimeout(timer);
        }
        timer = window.setTimeout(function() {
        if (scrollTop > refHeader.current.offsetHeight/5) {
            if (!refHeader.current.classList.contains( 'is-sticky' )){
                refHeader.current.classList.add( 'is-sticky' )
            }
        }else{
            if (refHeader.current.classList.contains( 'is-sticky' )){
                refHeader.current.classList.remove( 'is-sticky' )
            }

        }
        }, 100);
    };
    const navButtonHandler = () => {
        setIsOpenNav(!isOpenNav);
    }
    useEffect( () => {
        window.addEventListener( 'scroll', isSticky );
        return () => {
            window.removeEventListener( 'scroll', isSticky );
        };
    } );

    const headerClass = classNames({
        'header': true,
        'is-open-nav': isOpenNav
    });
    const LogoLink = () => <a href="/" className="header-logo_wrp">
                            <div className="header-logo_image">
                                <img src={Logo} alt="Logo muur.info"/>
                            </div>
                            <div className="header-logo_text">
                                <Lottie className={"image_lottie"} animationData={LogoAnimation} loop={true} />
                            </div>
                        </a>;
    return (
        <header className={headerClass} ref={refHeader}>
            <div className="header-row">
                <LogoLink/>
                {(navigation?.header.length>0 || navigation?.footer.length>0) &&
                <button className="button-nav" type={"button"} onClick={navButtonHandler}>
                    {!isOpenNav ? <ButtonNav/> : <IconCloseNav/>}
                </button>}
                <div className="header-col_right">
                    <div className="header-logo_mini">
                        <img src={MiniLogo} alt="muur.info"/>
                    </div>
                    <Navigation/>
                </div>
            </div>
        </header>
    )
}
