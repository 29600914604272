import "./accordion.style.scss";
import classNames from "classnames";
import React, { useState } from "react";

const Accordion = ({accordion, classes})=> {
    const AccordionItem = ({list, text, title}) => {
        const [isActive, setActive] = useState(false);
        const itemClass = classNames({
            'accordion-item muur-description': true,
            'is-active': isActive
        })
        const clickHandler = (e)=>{
            e.stopPropagation();
            e.preventDefault();
            setActive( !isActive );
        }
        return (
            <div className={itemClass}>
                <div className="accordion-item_header" onClick={clickHandler}>{title}</div>
                <div className="accordion-item_content">
                    <div dangerouslySetInnerHTML={{ __html: text}}/>
                    {list && list.length>0 &&
                        <ul className="muur-list">
                            {list.map((li, i)=><li key={i} dangerouslySetInnerHTML={{ __html: li }}/>)}
                        </ul>
                    }
                </div>
            </div>
        )
    }
    return (
        <div className={`accordion-wrapper ${classes}`}>
            {accordion?.map( (list, i) => <AccordionItem key={i} {...list} />)}
        </div>
    )
}
export default Accordion;
