import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
//import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Landing from "./pages/landing";
import NotFound from "./pages/not-found";
import Datenschutz from "./pages/datenschutz";
import Impressum from "./pages/impressum";
const router = createBrowserRouter([
    {
        path: "/",
        element: <Landing />,
        errorElement: <NotFound />,
        loader: () => fetch( "/locales/de/landing.json" )
    },
    {
        path: "/datenschutz",
        element: <Datenschutz />,
        errorElement: <NotFound />,
        loader: () => fetch( "/locales/de/datenschutz.json" )
    },
    {
        path: "/impressum",
        element: <Impressum />,
        errorElement: <NotFound />,
        loader: () => fetch( "/locales/de/impressum.json" )
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

//reportWebVitals(console.log);
