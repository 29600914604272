import { ReactComponent as IconCarrot } from "../../assets/icons/carrot.svg";
import "./button-popup.style.scss";
import classNames from "classnames";
import Lottie from "lottie-react";
import PigAnimation from "../../assets/lottie/pig.json";

const ButtonPopup = ({ data, classes, onClick})=> {
    const {image, shortDescription:name, simple, lottie, id} = data;
    return (
        <button className={`button-popup ${classes}`} onClick={()=>onClick(id)}>
            {(image || lottie) &&
                <span className="button-popup_image__wrapper" data-aos="fade-left">
                    {image && <img className="button-popup_image" src={image} alt=""/>}
                    {lottie==="PIG" && <Lottie className={"image_lottie"} animationData={PigAnimation} loop={true} />}
                    {(!simple || !name) && <span className="button-popup_icon icon-carrot"><IconCarrot/></span>}
                </span>
            }
            {name &&
                <span className="button-popup_shortdescr" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                    <span className={classNames( {
                        "button-popup_title": true,
                        "button-popup_title__small": simple
                    } )} dangerouslySetInnerHTML={{ __html: name }}/>
                        {simple && <span className="button-popup_icon icon-carrot"><IconCarrot/></span>}
                </span>
            }
        </button>
    )
}
export default ButtonPopup;
