import "./navigation.style.scss";
import { useLoaderData } from "react-router-dom";
import { Link } from "react-scroll";
import classNames from "classnames";

export const Navigation = ()=>{
    const { navigation } = useLoaderData();
    return (
        <nav className={"nav"}>
            <ul className={"nav-list"}>
                {navigation?.header?.map(({ link, anchor, target, name, fontColor, bgColor }, i)=>{
                    const itemClass = classNames({
                        'nav-item': true,
                        [`${bgColor ?? "primary"}-bg`]: true,
                        [`${fontColor ?? "white"}-color`]: true
                    })
                    return (
                        <li key={i}>
                            {anchor &&
                            <Link className={itemClass} activeClass="is-active" hashSpy smooth spy to={anchor} delay={0} duration={600} offset={-120}>
                                <span>{name}</span>
                            </Link>}
                            {!anchor && <a className={itemClass} href={link ?? ""} target={target}><span>{name}</span></a>}
                        </li>
                    );
                })}
                {navigation?.footer?.map(({ link, anchor, target, name, fontColor, bgColor }, i)=>{
                    const itemClass = classNames({
                        'nav-item': true,
                        [`${bgColor ?? "secondary"}-bg`]: true,
                        [`${fontColor ?? "white"}-color`]: true
                    })
                    return (
                        <li key={i} className={"nav-item_mobile"}>
                            {anchor &&
                                <Link className={itemClass} activeClass="is-active" hashSpy smooth spy to={anchor} delay={0} duration={400} offset={-120}>
                                    <span>{name}</span>
                                </Link>}
                            {!anchor && <a className={itemClass} href={link ?? ""} target={target}><span>{name}</span></a>}
                        </li>
                    )
                })}
            </ul>
        </nav>
    )
}
