import React, {  useCallback, useEffect, useRef, useState } from "react";
import "./insta-feed.style.scss";
import { ReactComponent as IconPlay } from "../../assets/icons/play.svg";

const Feed = ({ feed, username }) => {
    const { id, caption, media_type, media_url, permalink, thumbnail_url} = feed
    let post;

    switch (media_type) {
        case "VIDEO":
            post = (
                <>
                    <img id={id} src={thumbnail_url} alt={caption}/>
                    <span className="play-icon"><IconPlay/></span>
                </>
            )
            break;
        case "CAROUSEL_ALBUM":
            post = (
                <img id={id} src={media_url} alt={caption}/>
            );
            break;
        default:
            post = (
                <img id={id} src={media_url} alt={caption}/>
            );
    }

    return (
        <a href={permalink} target="_blank" rel="noreferrer" className={"instafeed-item"}>
            <span className="instafeed-item__media">{post}</span>
            <span className="instafeed-item__caption"><p>{caption}</p>{username && <><br/> <span>@{username}</span></>}</span>
        </a>
    );
}
export const InstaFeed = ()=>{
    const [feeds, setFeedsData] = useState([]);
    const [userName, setUserName] = useState(null);
    const token = 'IGQWROYURYMFEwTVJqVVpIcWp2UFh6aFRnUmdPa0lMOWdwQzVlZA3lqUzlweWxJZAldPUi1vb0ptbFAzMzhDbGxfbjE4U0NVZAVZApWF9PYlM0VjRNbUo1aHBiVmVrOWpKaDBJaldNR1VncEdGSGIyQTlmMllfYXZAQYXcZD';
    const sliderRef = useRef(null);
    const draggableSlider = useCallback(() => {
        const slider = sliderRef.current;
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener("mousedown", (e) => {
            isDown = true;
            slider.classList.add("active");
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener("mouseleave", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mouseup", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mousemove", (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 1.5;
            slider.scrollLeft = scrollLeft - walk;
        });
    },[sliderRef]);
    useEffect(() => {
        if ( feeds?.length<1 ) {
            const abortController = new AbortController();
            fetch( `https://graph.instagram.com/me?fields=id,username&access_token=${token}` )
                .then( response => response.json() )
                .then( result => {
                    setUserName( result.username )
                } )
                .catch( (error) => {console.log( 'error', error )} );
            fetch( `https://graph.instagram.com/me/media?fields=media_count,media_type,permalink,media_url,thumbnail_url,caption&access_token=${token}` )
                .then( response => response.json() )
                .then( result => {
                    setFeedsData( result.data )
                } )
                .catch( (error) => {console.log( 'error', error )} );
            return () => {
                abortController.abort();
            };
        }
    }, [token, feeds]);
    useEffect(()=>{
        if ( sliderRef ) {
            draggableSlider();
        }
    },[sliderRef, draggableSlider])
    return (
        <section className={"section section-instafeed"}>
            <div className="instafeed-slider" ref={sliderRef}>
                <div className="instafeed-list">
                    {feeds?.map((feed, i) => i<50 ? (
                        <Feed key={feed.id} feed={feed} username={userName}/>
                    ) : null)}
                </div>
            </div>
        </section>
    )
}
