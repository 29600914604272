import { useRef, useState } from "react";
import "./video-player.style.scss";
import classNames from "classnames";
import { ReactComponent as IconPlay } from "../../assets/icons/play.svg";
import { ReactComponent as IconPause } from "../../assets/icons/pause.svg";

export const VideoPlayer = ({video})=>{
    const [isPlaying, setIsPlaying] = useState(false);
    const [, setProgress] = useState(0);
    const videoRef = useRef(null);

    const togglePlay = () => {
        document.querySelectorAll('video').forEach(vid => vid.pause());
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleProgress = () => {
        const duration = videoRef.current.duration;
        const currentTime = videoRef.current.currentTime;
        const progress = (currentTime / duration) * 100;
        setProgress(progress);
        if ( progress===100 ){
            setIsPlaying(false);
        }
    };
    const handlePlay = () => {
        setIsPlaying(true);
    };
    //const handlePause = () => {
    //    setIsPlaying(false);
    //};
    const videoClass = classNames({
        "video_player": true,
        "is-playing": isPlaying
    })
    if (!video) return;
    return (
        <div className={videoClass}>
            <video width="100%" height="auto" controls={isPlaying}
                onTimeUpdate={handleProgress}
                   onPlay={handlePlay}
                   //onPause={handlePause}
                   ref={videoRef}  controlsList="nodownload" onContextMenu={(e)=> e.preventDefault()}>
                {video?.urlWEBM && <source src={video.urlWEBM} type="video/webm"/>}
                {video?.urlMP4 && <source src={video.urlMP4} type="video/mp4"/>}
            </video>
            <div className="video_poster" onClick={togglePlay}>
                {video?.poster && <img src={video.poster} alt=""/>}
            </div>
            <button onClick={togglePlay} className="video_button">
                {!isPlaying ? <IconPlay/> : <IconPause/>}
            </button>
            {/*<progress value={progress} max="100" />*/}
        </div>
    )
}
