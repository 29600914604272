import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import { Header } from "../components/header/header.view";
import { TextSection } from "../components/text-section/text-section.view";

const Datenschutz = ()=>{
    useEffect(() => {
        AOS.init({
            easing: 'linear',
            once: true
        });
        AOS.refresh();
    }, []);
    return (
        <main className={"main"}>
            <Header/>
            <TextSection/>
        </main>
    )
}
export default Datenschutz;
