import React, { useEffect, useRef, useState } from "react";
import "./infographic.style.scss";
import classNames from "classnames";
import { ReactComponent as IconClose } from "../../assets/icons/close.svg";
import { WieInfographic } from "./wie/wie-infographic.view";
import Accordion from "../accordion/accordion.view";

const InfographicItem = ({accordion, image, description, descriptionImage, preview, clientPosition, index, active, action}) => {
    const detailsRef = useRef(null);
    const itemClass = classNames({
        'infographic-item': true,
        'is-hover': index === active,
        'is-disabled': active && index!==active,
    });
    const [position, setPosition] = useState({ top:0, left: 0 });
    useEffect( () => {
        const detItem   = detailsRef.current;
        const detRec    = { width: detItem.offsetWidth, height: detItem.offsetHeight };
        const screenRec = { width: window.innerWidth, height: window.innerHeight };
        const left      = clientPosition.left + detRec.width > screenRec.width
                          ? (clientPosition.top + detRec.height > document.documentElement.scrollTop + screenRec.height
                             ? ((clientPosition.left - detRec.width + 1 - clientPosition.width) >= 0
                                ? clientPosition.left - detRec.width + 1 - clientPosition.width
                                : ((clientPosition.left - detRec.width + 1 - clientPosition.width / 2) >= 0
                                   ? clientPosition.left - detRec.width / 2 + 1 - clientPosition.width / 2
                                   : clientPosition.left - clientPosition.width / 2 - (detRec.width - clientPosition.width) / 2
                                )
                             )
                             : (clientPosition.left - detRec.width + (detRec.width - clientPosition.width) / 2 > screenRec.width)
                               ? clientPosition.left + (screenRec.width - (detRec.width + clientPosition.left)) + 1
                               : clientPosition.left - detRec.width + (detRec.width - clientPosition.width) / 2 + 1
                          )
                          : ((clientPosition.top + detRec.height > document.documentElement.scrollTop + screenRec.height)
                             ? clientPosition.left - 1
                             : clientPosition.left - detRec.width + (detRec.width - clientPosition.width) / 2)
        const top       = clientPosition.top + detRec.height > document.documentElement.scrollTop + screenRec.height
                          ? (clientPosition.left + detRec.width > screenRec.width
                             ? ((clientPosition.left - detRec.width + 1 - clientPosition.width / 2) >= 0
                                ? clientPosition.top + (document.documentElement.scrollTop + screenRec.height - (clientPosition.top + detRec.height)) + 1
                                : ((clientPosition.top - detRec.height - clientPosition.height)
                                   ? clientPosition.top - detRec.height - clientPosition.height
                                   : ((clientPosition.top - detRec.height - clientPosition.height / 2)
                                      ? clientPosition.top - detRec.height - clientPosition.height / 2
                                      : clientPosition.top - clientPosition.height / 2)
                                )
                             )
                             : clientPosition.top + (document.documentElement.scrollTop + screenRec.height - (clientPosition.top + detRec.height))
                          )
                          : clientPosition.top - 1;
        setPosition( { top, left } );
        // eslint-disable-next-line
    }, [active] );

    return (
        <div className={itemClass}
             onMouseEnter={(e)=>action(e, index, false)}
             onMouseLeave={(e)=>action(e, null, true)}
             onClick={(e)=>action(e, index, false)}
        >
            {!!preview &&
            <div className="infographic-item_preview">
                <img src={preview} alt=""/>
                <div className="infographic-item_count">{index}</div>
            </div>}
            <div className={"infographic-item_details"} ref={detailsRef} style={{top: position.top, left: position.left}}>
                <button type="button" className="infographic-item_details__close" onClick={(e)=>action(e, null, true)}><IconClose/></button>
                {image && <div className="infographic-item_details__preview">
                    <img src={image} alt=""/>
                </div>}
                {(accordion || description || descriptionImage) &&
                <div className="infographic-item_details__content">
                    {description && <div className="infographic-item_details__text muur-description">
                        <div dangerouslySetInnerHTML={{__html:description}}/>
                    </div>}
                    {descriptionImage &&
                        <div className="infographic-item_details__icon">
                            <img src={descriptionImage} alt=""/>
                        </div>
                    }
                    {accordion && <Accordion accordion={accordion} classes={"infographic-item_details_accordion"}/>}
                </div>}
            </div>
        </div>
    )
}
export const Infographic = ({description, image, classes, component, listPreviews})=>{
    const [activeId, setActiveID] = useState(null);
    const [clientPosition, setPosition] = useState( { width: 0, height: 0, top:0, left: 0 });
    const hoverHandler = (e, id, hide= false)=>{
        e.preventDefault();
        e.stopPropagation();
        const scrollTop = document.documentElement.scrollTop ?? 0;
        if ( hide ) {
            setActiveID(null);
        }else {
            setPosition( {
                width: e.currentTarget.getBoundingClientRect().width ?? 0,
                height: e.currentTarget.getBoundingClientRect().height ?? 0,
                top: (scrollTop + (e.currentTarget.getBoundingClientRect().top ?? 0) + (e.currentTarget.getBoundingClientRect().height ?? 0)) ?? 0,
                left: ((e.currentTarget.getBoundingClientRect().left ?? 0) + (e.currentTarget.getBoundingClientRect().width ?? 0) )?? 0
            } )
            setActiveID(id)
        }
    }
    return (
        <div className={`infographic-wrapper ${classes}`}>
            {component && component==="WieInfographic" && <div className="infographic-vector"><WieInfographic action={hoverHandler} active={activeId}/></div>}
            {image && <div className="infographic-image"> <img src={image} alt=""/> </div>}
            <div className="infographic-description" data-aos="fade-up" dangerouslySetInnerHTML={{ __html: description }}/>
            <div className="infographic-list">
                {listPreviews?.map((item, i)=><InfographicItem key={i} {...item} index={i+1} clientPosition={clientPosition} action={hoverHandler} active={activeId}/>)}
            </div>
        </div>
    )
}
