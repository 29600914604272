import { ReactComponent as WieInfographicSVG } from "../../../assets/infographic/wie-infographic-without-linsk.svg";
import "./wie-infographic.style.scss";
import classNames from "classnames";

export const WieInfographic = ({active, action}) => {
    return (
        <div className="wieInfographic-wrapper">
            <WieInfographicSVG/>
            <svg className={`wieInfographic-actionsSvg ${active ? 'is-hover' : ''}`} width="1327" height="917" viewBox="0 0 1327 917" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="links">
                        <g id="link_1"
                           className={classNames({
                               'is-active': active===1,
                               'wieInfographic-actionsSvg_item' : true,
                               'is-disabled' :  active && active!==1
                           })}
                           onMouseEnter={(e)=>action(e, 1, false)}
                           onMouseLeave={(e)=>action(e, null, true)}
                           onClick={(e)=>action(e, 1, false)}>
                            <ellipse id="Ellipse 4" cx="240" cy="679.429" rx="19" ry="18.984" fill="#E52C1A"/>
                            <path id="1" d="M240.89 687.559C240.29 687.559 239.834 687.079 239.834 686.503V673.615L237.266 675.535C237.098 675.655 236.906 675.727 236.69 675.727C236.138 675.727 235.706 675.295 235.706 674.743C235.706 674.407 235.85 674.143 236.09 673.951L239.978 671.047C240.194 670.879 240.506 670.783 240.818 670.783C241.538 670.783 241.922 671.311 241.922 671.959V686.503C241.922 687.079 241.466 687.559 240.89 687.559Z" fill="white"/>
                        </g>
                        <g id="link_2"
                           className={classNames({'is-active': active===2,
                               'wieInfographic-actionsSvg_item' : true,
                               'is-disabled' :  active && active!==2
                           })}
                            onMouseEnter={(e)=>action(e,2, false)}
                            onMouseLeave={(e)=>action(e,null, true)}
                            onClick={(e)=>action(e,2, false)}>
                            <ellipse id="Ellipse 4_2" cx="256" cy="788.338" rx="19" ry="18.984" fill="#E52C1A"/>
                            <path id="2" d="M260.182 796.348H250.846C250.222 796.348 249.838 795.916 249.838 795.364C249.838 795.028 249.982 794.692 250.366 794.332L255.094 789.916C257.062 788.068 258.646 786.628 258.646 784.612C258.646 782.884 257.446 781.54 255.478 781.54C253.942 781.54 252.814 782.428 252.286 783.844C252.118 784.3 251.782 784.588 251.278 784.588C250.606 784.588 250.222 784.156 250.222 783.604C250.222 783.388 250.27 783.244 250.366 782.956C251.182 780.844 253.126 779.548 255.55 779.548C258.694 779.548 260.758 781.732 260.758 784.54C260.758 787.084 258.982 788.98 256.75 791.068L253.246 794.332H260.182C260.734 794.332 261.19 794.788 261.19 795.34C261.19 795.892 260.734 796.348 260.182 796.348Z" fill="white"/>
                        </g>
                        <g id="link_3"
                           className={classNames({'is-active': active===3,
                               'wieInfographic-actionsSvg_item' : true,
                               'is-disabled' :  active && active!==3
                           })}
                            onMouseEnter={(e)=>action(e,3, false)}
                            onMouseLeave={(e)=>action(e,null, true)}
                            onClick={(e)=>action(e,3, false)}>
                            <ellipse id="Ellipse 4_3" cx="573" cy="845.984" rx="19" ry="18.984" fill="#E52C1A"/>
                            <path id="3" d="M572.565 854.234C569.853 854.234 567.981 852.986 567.093 850.922C567.045 850.778 567.021 850.634 567.021 850.49C567.021 849.89 567.477 849.41 568.053 849.41C568.509 849.41 568.869 849.65 569.085 850.154C569.637 851.45 570.741 852.242 572.565 852.242C574.509 852.242 575.925 851.162 575.925 849.314C575.925 847.538 574.605 846.578 572.565 846.578H571.485C570.933 846.578 570.501 846.122 570.501 845.57C570.501 845.042 570.933 844.586 571.485 844.586H572.565C574.437 844.586 575.565 843.506 575.565 841.874C575.565 840.218 574.365 839.186 572.421 839.186C570.645 839.186 569.613 840.05 569.133 841.178C568.917 841.706 568.533 841.994 568.077 841.994C567.477 841.994 567.045 841.514 567.045 840.914C567.045 840.77 567.093 840.554 567.189 840.338C567.981 838.442 569.805 837.194 572.373 837.194C575.685 837.194 577.677 839.138 577.677 841.85C577.677 843.338 577.029 844.706 575.637 845.522C577.245 846.218 578.037 847.634 578.037 849.29C578.037 852.218 575.901 854.234 572.565 854.234Z" fill="white"/>
                        </g>
                        <g id="link_4"
                           className={classNames({'is-active': active===5,
                               'wieInfographic-actionsSvg_item' : true,
                               'is-disabled' :  active && active!==5
                           })}
                            onMouseEnter={(e)=>action(e,5, false)}
                            onMouseLeave={(e)=>action(e,null, true)}
                            onClick={(e)=>action(e,5, false)}>
                            <ellipse id="Ellipse 4_4" cx="594" cy="713.984" rx="19" ry="18.984" fill="#E52C1A"/>
                            <path id="5" d="M593.546 722.234C590.93 722.234 589.034 720.986 588.146 718.922C588.098 718.802 588.05 718.658 588.05 718.49C588.05 717.89 588.53 717.41 589.13 717.41C589.586 717.41 589.946 717.674 590.114 718.082C590.666 719.426 591.794 720.242 593.522 720.242C595.874 720.242 597.17 718.73 597.17 716.738C597.17 714.674 595.802 713.21 593.522 713.21C592.442 713.21 591.53 713.618 590.786 714.314C590.498 714.578 590.162 714.77 589.73 714.77C588.938 714.77 588.314 714.098 588.41 713.234L589.226 706.49C589.322 705.794 589.73 705.434 590.426 705.434H597.866C598.418 705.434 598.874 705.89 598.874 706.442C598.874 706.994 598.418 707.45 597.866 707.45H591.074L590.474 712.25C591.29 711.602 592.37 711.218 593.714 711.218C597.218 711.218 599.282 713.69 599.282 716.714C599.282 719.834 597.146 722.234 593.546 722.234Z" fill="white"/>
                        </g>
                        <g id="link_5"
                           className={classNames({'is-active': active===6,
                               'wieInfographic-actionsSvg_item' : true,
                               'is-disabled' :  active && active!==6
                           })}
                           onMouseEnter={(e)=>action(e,6, false)}
                           onMouseLeave={(e)=>action(e,null, true)}
                           onClick={(e)=>action(e,6, false)}>
                            <ellipse id="Ellipse 4_5" cx="716" cy="820.984" rx="19" ry="18.984" fill="#E52C1A"/>
                            <path id="6" d="M714.522 828.84C711.138 828.84 708.714 826.44 708.714 823.224C708.714 821.232 709.77 819.528 711.81 816.864L715.194 812.448C715.482 812.064 715.842 811.92 716.178 811.92C716.706 811.92 717.162 812.304 717.162 812.856C717.162 813.096 717.066 813.408 716.826 813.72L713.97 817.368C713.826 817.56 713.682 817.752 713.538 817.92C713.946 817.8 714.402 817.752 714.858 817.752C718.002 817.752 720.282 820.008 720.282 823.224C720.282 826.416 717.882 828.84 714.522 828.84ZM714.498 826.872C716.73 826.872 718.17 825.312 718.17 823.272C718.17 821.112 716.73 819.672 714.522 819.672C712.41 819.672 710.826 821.184 710.826 823.32C710.826 825.336 712.242 826.872 714.498 826.872Z" fill="white"/>
                        </g>
                        <g id="link_6"
                           className={classNames({'is-active': active===7,
                               'wieInfographic-actionsSvg_item' : true,
                               'is-disabled' :  active && active!==7
                           })}
                           onMouseEnter={(e)=>action(e,7, false)}
                           onMouseLeave={(e)=>action(e,null, true)}
                           onClick={(e)=>action(e,7, false)}>
                            <ellipse id="Ellipse 4_6" cx="876" cy="786.984" rx="19" ry="18.984" fill="#E52C1A"/>
                            <path id="7" d="M873.613 795.114C873.061 795.114 872.605 794.658 872.605 794.082C872.605 793.938 872.629 793.842 872.701 793.698L878.413 780.45H870.757C870.205 780.45 869.749 779.994 869.749 779.442C869.749 778.89 870.205 778.434 870.757 778.434H879.589C880.405 778.434 880.885 778.89 880.885 779.538C880.885 779.898 880.669 780.378 880.525 780.69L874.549 794.49C874.405 794.85 874.045 795.114 873.613 795.114Z" fill="white"/>
                        </g>
                        <g id="link_7"
                           className={classNames({'is-active': active===4,
                               'wieInfographic-actionsSvg_item' : true,
                               'is-disabled' :  active && active!==4
                           })}
                           onMouseEnter={(e)=>action(e,4, false)}
                           onMouseLeave={(e)=>action(e,null, true)}
                           onClick={(e)=>action(e,4, false)}>
                            <ellipse id="Ellipse 4_7" cx="460" cy="753.984" rx="19" ry="18.984" fill="#E52C1A"/>
                            <path id="4" d="M462.218 762.114C461.642 762.114 461.186 761.61 461.186 761.058V757.578H454.322C453.65 757.578 453.074 757.146 453.074 756.474C453.074 756.138 453.194 755.826 453.338 755.61L460.562 745.938C460.826 745.578 461.234 745.338 461.786 745.338C462.746 745.338 463.25 746.01 463.25 746.826V755.562H464.954C465.506 755.562 465.962 756.018 465.962 756.57C465.962 757.122 465.506 757.578 464.954 757.578H463.25V761.058C463.25 761.61 462.794 762.114 462.218 762.114ZM461.186 748.218L455.714 755.562H461.186V748.218Z" fill="white"/>
                        </g>
                        <g id="link_8"
                           className={classNames({'is-active': active===8,
                               'wieInfographic-actionsSvg_item' : true,
                               'is-disabled' :  active && active!==8
                           })}
                           onMouseEnter={(e)=>action(e,8, false)}
                           onMouseLeave={(e)=>action(e,null, true)}
                           onClick={(e)=>action(e,8, false)}>
                            <ellipse id="Ellipse 4_8" cx="1040" cy="683.984" rx="19" ry="18.984" fill="#E52C1A"/>
                            <path id="8" d="M1039.51 692.234C1035.96 692.234 1033.75 690.17 1033.75 687.41C1033.75 685.634 1034.69 684.122 1036.37 683.522C1035 682.826 1034.18 681.53 1034.18 679.73C1034.18 677.258 1036.15 675.194 1039.51 675.194C1042.87 675.194 1044.84 677.258 1044.84 679.73C1044.84 681.53 1044.02 682.826 1042.68 683.522C1044.31 684.122 1045.25 685.634 1045.25 687.41C1045.25 690.17 1043.06 692.234 1039.51 692.234ZM1039.51 682.538C1041.45 682.538 1042.75 681.386 1042.75 679.85C1042.75 678.314 1041.55 677.162 1039.51 677.162C1037.49 677.162 1036.27 678.314 1036.27 679.85C1036.27 681.386 1037.57 682.538 1039.51 682.538ZM1039.51 690.266C1041.74 690.266 1043.13 689.066 1043.13 687.386C1043.13 685.682 1041.62 684.506 1039.51 684.506C1037.37 684.506 1035.86 685.682 1035.86 687.386C1035.86 689.066 1037.28 690.266 1039.51 690.266Z" fill="white"/>
                        </g>
                        <g id="link_9"
                           className={classNames({'is-active': active===9,
                               'wieInfographic-actionsSvg_item' : true,
                               'is-disabled' :  active && active!==9
                           })}
                           onMouseEnter={(e)=>action(e, 9, false)}
                           onMouseLeave={(e)=>action(e, null, true)}
                           onClick={(e)=>action(e, 9, false)}>
                            <ellipse cx="380" cy="362.984" rx="19" ry="18.984" fill="#E52C1A"/>
                            <path d="M380.545 366.506H378.529V362.858L378.961 362.786C381.697 362.33 382.705 360.962 382.705 359.282C382.705 357.458 381.385 356.138 379.441 356.138C377.545 356.138 376.177 357.41 376.009 359.45H373.969C374.281 356.186 376.465 354.194 379.441 354.194C382.609 354.194 384.817 356.234 384.817 359.282C384.817 361.73 383.281 363.578 380.545 364.274V366.506ZM379.417 371.138C378.673 371.138 378.025 370.514 378.025 369.746C378.025 368.978 378.673 368.354 379.417 368.354C380.209 368.354 380.857 368.978 380.857 369.746C380.857 370.514 380.209 371.138 379.417 371.138Z" fill="white"/>
                        </g>
                        <g>
                            <path d="M495.402 252.382L495.245 242.945C495.245 241.464 493.113 240.774 489.956 240.837C487.707 240.875 484.747 241.64 484.76 243.146L485.141 265.207C485.168 270.051 488.562 273.565 488.588 278.886L488.614 285.21C488.522 285.323 488.456 285.499 488.456 285.7V286.44C488.456 286.628 488.522 286.804 488.614 286.917V286.955C488.614 287.406 488.969 287.77 489.39 287.758L491.482 287.733C491.916 287.733 492.258 287.356 492.258 286.904V286.867C492.35 286.741 492.416 286.578 492.416 286.377V285.637C492.416 285.436 492.35 285.273 492.258 285.147L492.232 278.823C492.206 273.502 495.639 269.85 495.613 264.994L495.573 262.647C492.89 261.769 489.996 261.631 487.549 262.308C487.47 258.87 487.391 255.444 487.299 252.006C489.772 251.315 492.679 251.478 495.389 252.369L495.402 252.382Z" fill="#A0CF6D"/>
                            <path d="M511.492 256.837C511.953 255.557 510.164 254.302 507.164 253.348C505.033 252.671 502.007 252.382 501.547 253.674L495.009 272.699C493.522 276.853 495.627 280.943 493.983 285.511L492.036 290.945C491.904 291.02 491.799 291.146 491.733 291.309L491.51 291.949C491.457 292.112 491.457 292.275 491.51 292.413V292.451C491.365 292.84 491.575 293.254 491.983 293.392L493.97 294.032C494.377 294.158 494.811 293.957 494.956 293.568V293.53C495.101 293.455 495.206 293.329 495.259 293.166L495.482 292.526C495.548 292.363 495.535 292.187 495.482 292.062L497.429 286.628C499.061 282.06 503.454 280.015 504.941 275.861L505.599 273.954C504.204 273.025 502.678 272.41 501.192 272.222C502.297 269.147 503.389 266.073 504.494 263.011C505.941 263.187 507.427 263.776 508.796 264.667L511.479 256.837H511.492Z" fill="#A0CF6D"/>
                            <path d="M522.173 275.296C521.476 273.916 520.121 272.297 519.187 272.724L505.545 279.011C502.532 280.354 501.348 283.416 498.046 284.896L494.113 286.653C494.008 286.628 493.889 286.641 493.758 286.691L493.297 286.892C493.179 286.942 493.087 287.03 493.047 287.118H493.021C492.745 287.256 492.613 287.569 492.745 287.833L493.389 289.125C493.521 289.389 493.85 289.502 494.139 289.376H494.165C494.271 289.389 494.389 289.376 494.508 289.326L494.968 289.125C495.086 289.075 495.178 288.987 495.218 288.9L499.151 287.143C502.453 285.662 505.755 286.791 508.768 285.449L512.359 283.792C512.149 283.039 511.701 282.374 511.188 281.847C513.398 280.943 515.516 280.09 517.648 279.074C518.121 279.425 518.49 280.09 518.674 280.881L522.41 279.162C523.331 278.747 523.12 277.254 522.147 275.309L522.173 275.296Z" fill="#A0CF6D"/>
                            <path d="M488.153 290.87L487.942 290.23C487.89 290.054 487.784 289.928 487.666 289.853L485.916 284.344C484.443 279.714 486.758 275.635 485.416 271.419L484.693 269.223C481.47 268.896 480.299 269.624 477.773 271.632C476.773 268.52 475.787 265.42 474.787 262.308C476.392 261.091 478.444 259.861 481.68 260.15L479.063 252.256C478.655 250.964 476.418 250.914 473.393 251.779C471.235 252.394 468.604 253.825 469.012 255.143L475.366 274.28C476.708 278.496 480.931 280.68 482.404 285.31L484.154 290.819C484.101 290.945 484.088 291.121 484.14 291.284L484.351 291.924C484.404 292.087 484.509 292.225 484.627 292.3V292.338C484.759 292.727 485.193 292.953 485.614 292.84L487.627 292.275C488.034 292.162 488.271 291.748 488.14 291.346V291.309C488.192 291.183 488.192 291.008 488.14 290.844L488.153 290.87Z" fill="#A0CF6D"/>
                            <path d="M452.108 290.391V290.353C452.122 290.215 452.108 290.052 452.029 289.889L451.727 289.287C451.648 289.124 451.516 289.011 451.385 288.96L448.754 283.803C446.544 279.461 448.083 275.157 446.07 271.204L437.151 253.095C436.533 251.866 433.586 252.506 431.573 253.422C428.745 254.727 427.153 256.17 427.771 257.387L431.442 264.841C432.678 263.8 434.059 263.047 435.454 262.695C436.94 265.619 438.427 268.531 439.913 271.455C438.466 271.806 437.059 272.597 435.796 273.688L436.69 275.508C438.703 279.461 443.281 280.979 445.491 285.309L448.122 290.466C448.083 290.604 448.109 290.768 448.188 290.931L448.491 291.533C448.57 291.684 448.701 291.809 448.833 291.859V291.897C449.043 292.261 449.504 292.424 449.885 292.248L451.753 291.382C452.135 291.207 452.293 290.768 452.108 290.391Z" fill="#A0CF6D"/>
                            <path d="M450.554 287.996H450.528L450.278 287.745L449.831 287.531C449.713 287.469 449.581 287.456 449.489 287.481L445.621 285.624C442.372 284.068 441.28 280.968 438.32 279.55L424.941 272.924C424.02 272.485 422.639 274.066 421.916 275.434C420.903 277.354 420.653 278.835 421.56 279.274L425.928 281.433C425.928 280.128 426.415 278.873 427.151 278.346C429.243 279.425 431.322 280.328 433.492 281.282C432.677 282.073 432.032 283.202 432.137 284.507L434.939 285.888C437.899 287.306 441.201 286.264 444.45 287.82L448.318 289.677C448.358 289.765 448.45 289.853 448.568 289.916L449.015 290.129L449.357 290.179H449.384C449.66 290.317 449.989 290.217 450.134 289.966L450.804 288.698C450.936 288.435 450.831 288.121 450.554 287.983V287.996Z" fill="#A0CF6D"/>
                            <path d="M468.472 250.262C465.709 249.371 463.657 249.358 463.236 250.538L460.671 257.578C463.539 257.502 465.67 258.845 467.078 259.987C466.078 262.836 465.065 265.697 464.065 268.546C461.842 266.651 460.408 265.961 457.566 266.124L456.843 268.106C455.488 271.984 457.501 275.837 456.014 280.078L454.251 285.148C454.133 285.211 454.041 285.336 453.975 285.499L453.764 286.089C453.712 286.24 453.712 286.403 453.764 286.516V286.553C453.633 286.917 453.83 287.306 454.199 287.419L456.04 288.009C456.422 288.122 456.816 287.934 456.948 287.57V287.532C457.08 287.47 457.172 287.344 457.224 287.181L457.435 286.591C457.487 286.428 457.487 286.277 457.435 286.164L459.198 281.095C460.684 276.841 464.644 274.933 465.999 271.055L472.405 253.474C472.826 252.27 470.432 250.864 468.472 250.237V250.262Z" fill="#A0CF6D"/>
                            <path d="M476.314 268.118C474.617 266.851 473.183 266.336 472.565 267.014L463.382 277.028C461.396 279.249 461.79 282.286 459.607 284.72L457.015 287.619L456.739 287.782L456.436 288.121L456.318 288.41V288.435C456.121 288.636 456.16 288.949 456.384 289.113L457.515 289.953C457.752 290.129 458.081 290.091 458.265 289.891H458.278L458.554 289.715L458.857 289.376L458.975 289.087L461.567 286.189C463.737 283.754 467.026 283.465 469.013 281.244L470.039 280.127C469.947 278.873 468.618 277.718 466.789 276.89C468.25 275.258 469.723 273.627 471.183 271.983C472.959 272.799 474.485 273.891 474.459 275.284L478.182 271.23C478.8 270.54 477.498 269.022 476.288 268.131L476.314 268.118Z" fill="#A0CF6D"/>
                            <path d="M456.2 264.09L455.398 242.029C455.332 240.523 452.346 239.92 450.122 240.008C446.991 240.134 444.926 240.924 444.992 242.418L445.268 250.085C447.807 249.119 450.543 248.818 452.938 249.307C453.082 252.746 453.227 256.171 453.372 259.61C450.964 259.108 448.202 259.422 445.65 260.4L445.794 264.466C446.031 269.31 449.636 272.774 449.886 278.082L450.188 284.407C450.096 284.532 450.043 284.708 450.057 284.896L450.096 285.637C450.096 285.825 450.175 286 450.267 286.113V286.151C450.293 286.603 450.649 286.954 451.083 286.942L453.161 286.854C453.582 286.841 453.911 286.452 453.885 286V285.963C453.977 285.837 454.016 285.662 454.016 285.473L453.977 284.733C453.977 284.532 453.898 284.369 453.806 284.256L453.503 277.931C453.24 272.623 456.437 268.921 456.2 264.077V264.09Z" fill="#A0CF6D"/>
                            <path d="M490.519 285.826C495.255 285.826 499.768 286.328 502.214 288.637C505.74 291.963 506.293 297.861 505.779 303.608C503.148 304.725 500.057 305.679 495.492 305.767C498.728 306.745 502.017 307.36 505.359 306.934C504.306 314.601 503.069 322.118 501.596 329.484C499.228 330.388 496.413 331.103 492.519 331.166C495.308 332.007 498.123 332.584 501.004 332.458C499.596 339.134 498.005 345.685 496.163 352.085C495.374 354.846 493.716 361.898 490.493 361.898C487.27 361.898 485.612 354.846 484.823 352.085C483.968 349.123 483.179 346.137 482.429 343.138C484.902 343.087 487.323 342.56 489.717 341.832C486.454 341.77 483.929 341.268 481.811 340.565C480.127 333.512 478.693 326.309 477.469 318.981C480.732 319.332 483.915 318.73 487.073 317.789C482.574 317.701 479.522 316.772 476.917 315.668C476.404 312.418 475.93 309.142 475.47 305.842C475.207 303.809 475.023 301.713 475.062 299.68C478.693 300.295 482.205 299.668 485.691 298.614C481.061 298.538 477.956 297.56 475.299 296.405C475.733 293.318 476.772 290.557 478.785 288.637C481.232 286.328 485.757 285.826 490.506 285.826" fill="#F99D3C"/>
                            <path d="M452.91 285.826C457.646 285.826 462.158 286.328 464.605 288.637C468.131 291.963 468.683 297.861 468.17 303.608C465.539 304.725 462.448 305.679 457.883 305.767C461.119 306.745 464.408 307.36 467.749 306.934C466.697 314.601 465.46 322.118 463.987 329.484C461.619 330.388 458.804 331.103 454.91 331.166C457.699 332.007 460.514 332.584 463.395 332.458C461.987 339.134 460.396 345.685 458.554 352.085C457.765 354.846 456.107 361.898 452.884 361.898C449.661 361.898 448.003 354.846 447.214 352.085C446.359 349.123 445.57 346.137 444.82 343.138C447.293 343.087 449.714 342.56 452.108 341.832C448.845 341.77 446.319 341.268 444.201 340.565C442.518 333.512 441.084 326.309 439.86 318.981C443.123 319.332 446.306 318.73 449.464 317.789C444.964 317.701 441.912 316.772 439.308 315.668C438.795 312.418 438.321 309.142 437.861 305.842C437.598 303.809 437.427 301.713 437.453 299.68C441.084 300.295 444.596 299.668 448.082 298.614C443.452 298.538 440.347 297.56 437.69 296.405C438.124 293.318 439.163 290.557 441.176 288.637C443.623 286.328 448.148 285.826 452.897 285.826" fill="#F99D3C"/>
                            <path d="M472.746 395.757C474.535 397.664 474.035 400.299 473.18 402.433C472.496 403.901 470.22 403.047 470.733 401.542C470.996 400.839 471.141 400.023 471.128 399.295C471.128 398.793 471.009 398.091 470.68 397.815C470.023 397.35 469.878 396.459 470.365 395.832C470.93 395.079 472.114 395.066 472.733 395.782L472.746 395.757Z" fill="#F3869E"/>
                            <path d="M470.276 405.593C469.066 407.613 465.895 407.927 464.212 406.283C463.067 405.304 462.778 403.535 463.383 402.242C464.172 400.335 466.514 399.406 468.461 399.506C471.118 399.469 473.618 401.502 474.302 403.961C474.841 405.655 474.644 407.211 474.867 408.755C474.933 409.131 475.091 409.408 475.17 409.357C475.67 410.424 475.17 411.679 474.039 412.156C472.92 412.633 471.605 412.156 471.105 411.077C470.079 408.102 472.762 405.128 470.329 402.731C469.04 401.401 465.264 401.64 465.501 403.936C465.659 404.94 467.421 405.367 467.882 404.262C468.816 402.656 471.263 404.037 470.25 405.58L470.276 405.593Z" fill="#F3869E"/>
                            <path d="M497.429 409.745C495.995 409.595 494.772 409.72 492.996 410.536C491.654 411.151 491.075 411.741 490.141 412.042C488.325 412.619 486.655 409.532 485.721 409.984C484.826 410.411 483.221 416.007 484.366 416.861C485.168 417.463 490.93 415.33 492.351 416.334C493.219 416.936 489.102 416.171 491.193 418.291C493.811 420.927 496.258 420.964 499.915 419.747C502.073 419.032 505.782 418.316 507.44 417.538C506.888 416.208 503.691 413.41 502.204 412.318C500.783 411.264 498.85 409.909 497.416 409.745H497.429Z" fill="#F3869E"/>
                            <path d="M497.086 411.455C496.086 411.342 495.258 411.43 494.021 412.007C493.1 412.434 492.692 412.848 492.048 413.048C490.798 413.45 490.824 411.417 490.18 411.718C489.561 412.007 488.456 415.885 489.246 416.474C489.798 416.888 489.969 415.081 490.943 415.772C491.548 416.198 491.337 415.885 492.784 417.353C494.587 419.172 496.284 419.197 498.81 418.357C499.994 417.968 501.875 417.566 503.164 417.152C503.624 417.001 503.822 416.462 503.532 416.11C502.717 415.132 501.191 413.814 500.388 413.224C499.402 412.496 498.073 411.555 497.086 411.455Z" fill="#C74980"/>
                            <path d="M450.701 410.387C452.161 410.45 453.371 410.776 455.081 411.893C456.371 412.734 456.897 413.436 457.805 413.876C459.567 414.754 462.988 412.445 463.895 413.035C464.764 413.612 465.829 419.598 464.593 420.288C463.738 420.778 458.134 417.703 456.621 418.494C455.687 418.983 458.462 418.243 456.147 420.087C453.266 422.371 450.793 422.02 447.228 420.213C445.123 419.146 441.479 417.829 439.874 416.775C440.571 415.507 444.044 413.135 445.649 412.244C447.188 411.391 449.254 410.299 450.714 410.362L450.701 410.387Z" fill="#F3869E"/>
                            <path d="M451.897 412.346C452.937 412.396 453.792 412.622 455.002 413.412C455.91 414.002 456.291 414.504 456.923 414.818C458.173 415.433 459.883 413.902 460.514 414.328C461.119 414.73 461.882 418.972 461.001 419.461C460.396 419.8 458.87 417.303 457.791 417.855C457.133 418.206 457.383 417.917 455.739 419.223C453.7 420.841 451.95 420.59 449.437 419.31C448.332 418.746 446.609 418.081 445.333 417.478C444.728 417.19 444.557 416.412 445.004 416.01C445.964 415.144 447.49 414.14 448.319 413.688C449.398 413.086 450.871 412.308 451.897 412.358V412.346Z" fill="#C74980"/>
                            <path d="M448.147 410.977C447.963 410.199 451.647 416.586 452.357 418.983C452.541 419.598 451.897 427.177 451.949 428.558C452.002 429.988 449.476 434.933 449.989 435.522C451.449 437.166 464.144 440.266 474.958 440.128C486.048 439.99 493.862 437.982 495.888 437.48C496.453 437.342 495.072 431.017 493.914 428.068C493.664 427.428 493.678 420.602 494.349 417.703C494.625 416.523 498.506 410.035 498.506 410.035C498.506 409.257 484.522 408.391 473.458 408.417C460.592 408.442 448.174 410.399 448.147 410.977Z" fill="#F3869E"/>
                            <path d="M466.895 421.115C466.895 423.663 464.738 425.721 462.067 425.721C459.397 425.721 457.239 423.663 457.239 421.115C457.239 418.568 459.397 416.51 462.067 416.51C464.738 416.51 466.895 418.568 466.895 421.115Z" fill="white"/>
                            <path d="M465.54 422.009C465.54 423.351 464.395 424.443 462.988 424.443C461.58 424.443 460.436 423.351 460.436 422.009C460.436 420.666 461.58 419.574 462.988 419.574C464.395 419.574 465.54 420.666 465.54 422.009Z" fill="#2B3232"/>
                            <path d="M491.179 419.007C491.179 421.567 488.995 423.651 486.312 423.651C483.628 423.651 481.444 421.567 481.444 419.007C481.444 416.447 483.628 414.364 486.312 414.364C488.995 414.364 491.179 416.447 491.179 419.007Z" fill="white"/>
                            <path d="M487.941 420.174C487.941 421.529 486.797 422.621 485.376 422.621C483.955 422.621 482.811 421.529 482.811 420.174C482.811 418.818 483.955 417.727 485.376 417.727C486.797 417.727 487.941 418.831 487.941 420.174Z" fill="#2B3232"/>
                            <path d="M483.14 429.7C483.14 433.365 479.26 436.326 474.484 436.326C469.709 436.326 465.828 433.365 465.828 429.7" fill="#C74980"/>
                            <path d="M462.209 425.393C462.209 419.834 469.181 415.329 474.193 415.329C478.785 415.329 486.178 419.834 486.178 425.393C486.178 430.953 481.06 431.894 474.443 431.894C467.826 431.894 462.209 430.953 462.209 425.393Z" fill="#F6A2B5"/>
                            <path d="M467.435 424.617C467.435 422.86 468.079 421.43 470 421.43C472.131 421.43 472.565 422.86 472.565 424.617C472.565 426.374 472.236 427.805 470 427.805C467.974 427.805 467.435 426.374 467.435 424.617Z" fill="#F3869E"/>
                            <path d="M475.433 424.617C475.433 422.86 476.077 421.43 477.998 421.43C480.129 421.43 480.563 422.86 480.563 424.617C480.563 426.374 480.234 427.805 477.998 427.805C475.972 427.805 475.433 426.374 475.433 424.617Z" fill="#F3869E"/>
                            <path d="M496.914 409.573C495.48 409.422 494.257 409.548 492.481 410.363C491.139 410.978 490.56 411.568 489.626 411.869C487.811 412.446 486.14 409.359 485.206 409.799C484.312 410.225 482.707 415.822 483.851 416.688C484.654 417.278 490.416 415.157 491.836 416.161C492.705 416.776 488.587 415.998 490.679 418.119C493.297 420.754 495.743 420.791 499.401 419.574C501.558 418.859 505.268 418.144 506.925 417.366C506.373 416.035 503.176 413.237 501.69 412.145C500.269 411.091 498.335 409.736 496.901 409.573H496.914Z" fill="#F3869E"/>
                            <path d="M496.575 411.29C495.575 411.177 494.746 411.265 493.509 411.842C492.588 412.269 492.181 412.683 491.536 412.883C490.286 413.285 490.313 411.252 489.668 411.553C489.05 411.842 487.945 415.72 488.734 416.309C489.287 416.723 489.458 414.916 490.431 415.607C491.036 416.033 490.826 415.72 492.273 417.188C494.075 419.007 495.772 419.032 498.298 418.192C499.482 417.803 501.363 417.401 502.652 416.987C503.113 416.836 503.31 416.297 503.021 415.945C502.205 414.967 500.679 413.649 499.876 413.059C498.89 412.331 497.561 411.39 496.575 411.277V411.29Z" fill="#C74980"/>
                            <path d="M450.701 410.387C452.161 410.45 453.371 410.776 455.081 411.893C456.371 412.734 456.897 413.436 457.805 413.876C459.567 414.754 462.988 412.445 463.895 413.035C464.764 413.612 465.829 419.598 464.593 420.288C463.738 420.778 458.134 417.703 456.621 418.494C455.687 418.983 458.462 418.243 456.147 420.087C453.266 422.371 450.793 422.02 447.228 420.213C445.123 419.146 441.479 417.829 439.874 416.775C440.571 415.507 444.044 413.135 445.649 412.244C447.188 411.391 449.254 410.299 450.714 410.362L450.701 410.387Z" fill="#F3869E"/>
                            <path d="M451.897 412.346C452.937 412.396 453.792 412.622 455.002 413.412C455.91 414.002 456.291 414.504 456.923 414.818C458.173 415.433 459.883 413.902 460.514 414.328C461.119 414.73 461.882 418.972 461.001 419.461C460.396 419.8 458.87 417.303 457.791 417.855C457.133 418.206 457.383 417.917 455.739 419.223C453.7 420.841 451.95 420.59 449.437 419.31C448.332 418.746 446.609 418.081 445.333 417.478C444.728 417.19 444.557 416.412 445.004 416.01C445.964 415.144 447.49 414.14 448.319 413.688C449.398 413.086 450.871 412.308 451.897 412.358V412.346Z" fill="#C74980"/>
                            <path d="M448.147 410.977C447.963 410.199 451.647 416.586 452.357 418.983C452.541 419.598 451.897 427.177 451.949 428.558C452.002 429.988 449.476 434.933 449.989 435.522C451.449 437.166 464.144 440.266 474.958 440.128C486.048 439.99 493.862 437.982 495.888 437.48C496.453 437.342 495.072 431.017 493.914 428.068C493.664 427.428 493.678 420.602 494.349 417.703C494.625 416.523 498.506 410.035 498.506 410.035C498.506 409.257 484.522 408.391 473.458 408.417C460.592 408.442 448.174 410.399 448.147 410.977Z" fill="#F3869E"/>
                            <path d="M466.895 421.115C466.895 423.663 464.738 425.721 462.067 425.721C459.397 425.721 457.239 423.663 457.239 421.115C457.239 418.568 459.397 416.51 462.067 416.51C464.738 416.51 466.895 418.568 466.895 421.115Z" fill="white"/>
                            <path d="M465.54 422.009C465.54 423.351 464.395 424.443 462.988 424.443C461.58 424.443 460.436 423.351 460.436 422.009C460.436 420.666 461.58 419.574 462.988 419.574C464.395 419.574 465.54 420.666 465.54 422.009Z" fill="#2B3232"/>
                            <path d="M491.179 419.007C491.179 421.567 488.995 423.651 486.312 423.651C483.628 423.651 481.444 421.567 481.444 419.007C481.444 416.447 483.628 414.364 486.312 414.364C488.995 414.364 491.179 416.447 491.179 419.007Z" fill="white"/>
                            <path d="M487.941 420.174C487.941 421.529 486.797 422.621 485.376 422.621C483.955 422.621 482.811 421.529 482.811 420.174C482.811 418.818 483.955 417.727 485.376 417.727C486.797 417.727 487.941 418.831 487.941 420.174Z" fill="#2B3232"/>
                            <path d="M483.14 429.7C483.14 433.365 479.26 436.326 474.484 436.326C469.709 436.326 465.828 433.365 465.828 429.7" fill="#C74980"/>
                            <path d="M462.209 425.393C462.209 419.834 469.181 415.329 474.193 415.329C478.785 415.329 486.178 419.834 486.178 425.393C486.178 430.953 481.06 431.894 474.443 431.894C467.826 431.894 462.209 430.953 462.209 425.393Z" fill="#F6A2B5"/>
                            <path d="M467.435 424.617C467.435 422.86 468.079 421.43 470 421.43C472.131 421.43 472.565 422.86 472.565 424.617C472.565 426.374 472.236 427.805 470 427.805C467.974 427.805 467.435 426.374 467.435 424.617Z" fill="#F3869E"/>
                            <path d="M475.433 424.617C475.433 422.86 476.077 421.43 477.998 421.43C480.129 421.43 480.563 422.86 480.563 424.617C480.563 426.374 480.234 427.805 477.998 427.805C475.972 427.805 475.433 426.374 475.433 424.617Z" fill="#F3869E"/>
                            <path d="M519.436 370.079C518.489 366.239 517.634 365.01 517.015 364.583C517.647 367.13 517.331 371.598 516.239 371.711C515.147 371.824 515.766 366.541 516.2 362.374C516.634 358.208 513.792 356.137 512.635 355.51C514.503 357.781 512.806 374.735 509.464 374.861C506.123 374.986 518.134 350.152 506.044 338.104C506.504 340.1 508.083 342.371 505.281 346.625C502.479 350.892 508.806 358.534 503.689 361.496C501.203 362.173 501.374 358.359 501.492 356.275C496.783 360.668 502.873 365.424 494.388 373.342C490.731 376.755 491.113 383.331 490.323 384.862C488.455 386.406 488.85 382.955 489.034 381.235C489.666 375.689 489.113 379.554 487.14 382.064C484.706 385.163 483.93 375.789 484.719 372.526C485.653 368.649 484.219 366.365 482.68 364.608C481.141 362.851 478.826 358.409 479.312 356.627C476.313 358.584 475.905 363.328 477.194 365.988C478.01 367.683 480.01 373.668 477.602 373.857C475.892 373.982 476.208 369.828 473.642 365.863C472.314 363.805 472.761 360.078 474.05 358.647C469.249 361.91 468.052 365.988 468.315 369.502C468.512 372.112 467.749 375.024 467.052 375.363C466.262 375.777 465.973 374.459 465.815 372.501C465.644 370.556 467.407 365.248 467.078 363.19C466.526 358.158 464.907 355.447 467.157 349.951C463.145 352.41 462.421 355.234 462.71 358.271C463.421 365.624 458.159 367.883 456.962 375.149C455.87 381.763 451.134 379.642 452.16 374.371C452.16 374.371 450.831 374.371 449.568 375.802C447.016 378.475 446.424 370.732 442.03 365.775C438.321 361.596 439.123 354.305 440.794 348.784C438.978 348.884 438.702 351.821 436.939 349.938C435.861 348.796 436.781 343.337 439.636 341.518C434.9 342.346 432.138 347.843 431.204 355.234C430.533 360.517 432.125 363.78 430.73 364.859C429.415 365.875 428.073 360.68 428.665 357.882C425.35 361.132 428.757 372.464 427.823 373.455C427.547 373.756 426.731 374.108 426.021 372.89C426.836 393.91 461.842 397.223 474.327 396.784C488.061 396.294 523.396 390.02 519.423 370.104V370.067H519.41L519.436 370.079Z" fill="#00B6F1"/>
                            <path d="M505.756 385.363C501.559 385.966 511.505 374.709 503.164 365.774C503.875 369.313 503.52 376.165 501.586 377.709C499.639 379.252 498.06 377.144 497.284 376.24C497.402 381.473 497.429 384.648 492.798 387.873C489.417 390.22 479.972 387.949 482.826 379.403C478.946 381.988 478.025 389.254 478.025 389.254C477.985 389.254 477.735 384.749 476.906 382.816C475.696 380.018 473.67 377.508 473.815 375.136C473.67 373.304 474.104 372.338 474.104 372.338C474.104 372.338 471.434 376.29 472.263 378.549C473.315 381.436 472.328 384.987 469.855 379.754C469.303 378.687 469.724 379.591 469.803 381.323C469.947 384.749 467.027 384.083 466.685 382.628C465.264 376.391 459.778 379.001 460.831 374.898C459.765 378.21 460.41 380.344 461.778 383.142C463.146 385.878 460.252 390.672 456.121 385.351C454.608 397.887 447.886 387.334 447.281 386.041C447.281 386.041 445.886 391.914 439.638 378.072C439.638 378.072 439.756 380.206 438.704 381.147C437.651 382.101 434.336 373.58 435.481 371.359L433.784 375.236C432.771 378.198 430.521 384.46 435.152 387.923C445.61 394.411 459.568 397.097 474.328 396.821C489.904 396.532 499.665 392.83 507.335 389.191C514.636 384.686 511.715 378.21 510.755 377.809C511.768 379.98 509.939 384.786 505.743 385.389L505.756 385.363Z" fill="#5BCBF5"/>
                        </g>
                    </g>
            </svg>
        </div>
    )
};
