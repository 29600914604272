import "./popup.style.scss";
import { ReactComponent as IconClose } from "../../assets/icons/close.svg";
export const Popup = ({closeAction, children})=>{
    return (
        <div className={`popup-wrapper`} data-aos="fade">
            <div className="popup-bg"></div>
            <div className="popup-container">
                <button className="popup-close" onClick={closeAction}><IconClose/></button>
                <div className="popup-body">
                    {children}
                </div>
            </div>
        </div>
    )
}
