import "./not-found-section.style.scss";
import classNames from "classnames";
import Lottie from "lottie-react";
import PigAnimation from "../../assets/lottie/pig.json";
import { ReactComponent as IconCarrot } from "../../assets/icons/carrot.svg";

export const NotFoundSection = ()=>{
        const sectionClass = classNames({
            [`white-bg`]: true,
            [`primary-color`]: true
        });
        return (
            <>
            <section className={`section section-not-found ${sectionClass}`}>
                <div className="section-not-found_container">
                    <div className={`section-not-found_content`}>
                        <div className={`section-not-found_content_row`}>
                            <div className={"section-not-found_404"}>4</div>
                            <Lottie className={"image_lottie"} animationData={PigAnimation} loop={true} />
                            <div className={"section-not-found_404"}>4</div>
                        </div>
                        <div className="section-not-found_title">Hoppla... <br/>Seite nicht gefunden.</div>
                        <a href="/" className={"section-not-found_btn"}><span>Zur Startseite</span> <span className="button-popup_icon icon-carrot primary-fill"><IconCarrot/></span></a>
                    </div>
                </div>
            </section>
            </>
        )
}
