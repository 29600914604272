import "./slide.style.scss";
import classNames from "classnames";
import Accordion from "../../accordion/accordion.view";
import { VideoPlayer } from "../../video-player/video-player.view";
export const MuurSlide = ({video, accordion, image, headline, subHeadline, description, background, fontColor, headlineAlignment, subHeadline2line, headline2line,link })=> {
    const headlineClass = classNames({
        'section-popup_slider_headline': true,
        [`${background?.headline ?? "secondary"}-bg`]: true,
        [`${fontColor?.headline ?? "white"}-color`]: true
    });
    const subHeadlineClass = classNames({
        'section-popup_slider_headline_sub': true,
        [`${background?.subHeadline ?? "secondary"}-bg`]: true,
        [`${fontColor?.subHeadline ?? "white"}-color`]: true
    });
    const headlineTop = headlineAlignment==="top";
    const Headline = ()=>(
        <h3 className="section-slider_slide_h3__wrapper muur-h3__wrapper">
            {headline && <div className={`muur-h3 ${headlineClass}`} data-aos="fade-up">{headline}</div>}
            {headline2line && <div className={`muur-h3 ${headlineClass}`} data-aos="fade-up">{headline2line}</div>}
            {subHeadline && <div className={`muur-h3_sub ${subHeadlineClass}`} data-aos="fade-up">{subHeadline}</div>}
            {subHeadline2line && <div className={`muur-h3_sub ${subHeadlineClass}`} data-aos="fade-up">{subHeadline2line}</div>}
        </h3>
    );
    return(
        <article className={`section section-slider_slide`} data-aos="fade">
            <div className="section-slider_slide_container">
                <div className={`section-slider_slide_preview
                        ${video ? "section-slider_slide_preview_video":""}
                    `}>
                    {image && <div className="section-slider_slide_bg">
                        <img src={image} alt="fill murray"/>
                    </div>}
                    {video && <div className="section-slider_slide_video">
                        <VideoPlayer video={video}/>
                    </div>}
                    {!headlineTop && <Headline/>}
                </div>
                <div className={`section-slider_slide_content`}>
                    {headlineTop && <Headline/>}
                    <div className="section-slider_slide_row">
                        <div className="section-slider_slide_description muur-description" data-aos="fade-up"
                             dangerouslySetInnerHTML={{ __html: description }}/>
                        {link?.url && <a href={link?.url} target={link?.target} className="section-slider_slide_link muur-link" data-aos="fade-up">{link?.text}</a>}
                        {accordion && <Accordion accordion={accordion} classes={"section-slider_slide_accordion"}/>}
                    </div>
                </div>
            </div>
        </article>
    )
};
