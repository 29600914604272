import "./text-section.style.scss";
import { useLoaderData } from "react-router-dom";
import classNames from "classnames";
export const TextSection = ()=>{
    const { content, bgColor, textColor, headline} = useLoaderData();
        const sectionClass = classNames({
            [`${bgColor ?? "primary"}-bg`]: true,
            [`${textColor ?? "white"}-color`]: true
        });
        return (
            <>
            <section className={`section section-default ${sectionClass}`}>
                <div className="section-default_container">
                    <div className={`section-default_content muur-description`}>
                        {headline && <h1 className="section-default_h1__wrapper muur-h1" data-aos="fade-up">{headline}</h1>}
                        {content?.htmlBlocks?.map( ({ text, list, title }, i) =>
                            <div key={i} className="section-default_description" data-aos="fade-up">
                                {title && <div className="muur-text_title" dangerouslySetInnerHTML={{ __html: title }}/>}
                                {text && <div className="muur-text" dangerouslySetInnerHTML={{ __html: text }}/>}
                                {list && list.length>0 &&
                                    <ul className="muur-list">
                                        {list.map((li,i)=><li key={i} dangerouslySetInnerHTML={{ __html: li }}/>)}
                                    </ul>
                                }
                            </div>
                        )}
                    </div>

                </div>
            </section>
            </>
        )
}
